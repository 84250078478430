import React from "react";

export default function Blocked() {
    return (
        <div>
            <h1>You have been blocked from accessing this site</h1>
            <div>Your block occurred for one of the following reasons</div>
            <ul>
                <li>Your address failed our sanctions check</li>
                <li>Your location is in a sanctioned country</li>
            </ul>
        </div>
    )
}