import assert from "assert";
import { SERVER_LOCATION } from "./utils";
import axios from "axios";
import { ethers } from "ethers";

export async function isAdmin(address) {
    const resp = await axios.get(`${SERVER_LOCATION}/isAdmin/address?address=${address}`);
    return resp.data;
};

export function hello() {
    return "hello";
}

export async function liftBan(item) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = await provider.getSigner();
    const now = new Date()
    const message = Math.floor(now.getTime()/1000).toString();
    //const message = "Hello World!";
    const signature = await signer.signMessage(message);
    const postData = {
        signature: signature,
        ipfsHash: item,
        message: message
    };

    const url = `${SERVER_LOCATION}/removeBan`
    try {
        const resp = await axios.post(url, postData);
        console.log(`server response: ${resp}`);
    } catch (err) {
        console.log("Error lifting ban:", err);
    }
}

export async function isSanctioned(address) {
    let sanctioned = true;
    const url = `${SERVER_LOCATION}/isSanctioned/address?address=${address}`;
    const resp = await axios.get(url, {timeout: 10000});
    try {
        if (resp.data === false) {
            sanctioned = false;
        }
    } catch (err) {
        console.log("Error checking sanctions:", err);
        console.log("Status code:", resp.status);
    }
    if (typeof sanctioned !== "boolean") {
        throw new Error(`isSanctioned() returned ${typeof sanctioned}, should return boolean`);
    }
    return sanctioned;
}