function buffer2base64(buffer) {
    const binary = String.fromCharCode.apply(null, new Uint8Array(buffer));

    return window.btoa(binary);
}

function base642Buffer(base64String) {
    const binary = window.atob(base64String);

    const len = binary.length;
    const buffer = new ArrayBuffer(len);

    const view = new Uint8Array(buffer);

    for (let i=0; i<len; i++) {
        view[i] = binary.charCodeAt(i);
    }

    return buffer;
}

async function generateWebCryptoKeyPair() {
    const keypair = await crypto.subtle.generateKey({
        name: "RSA-OAEP",
        modulusLength: 2048,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: {
            name: "SHA-256"
        },
    }, true, ["encrypt", "decrypt"]);

    const publicKeyBuffer = await crypto.subtle.exportKey("spki", keypair.publicKey);
    const privateKeyBuffer = await crypto.subtle.exportKey("pkcs8", keypair.privateKey);

    const publicKeyBase64 = buffer2base64(publicKeyBuffer);
    const privateKeyBase64 = buffer2base64(privateKeyBuffer);

    return {
        pubKey: publicKeyBase64, privKey: privateKeyBase64
    };
}

async function encryptData(publicKeyBase64, data) {
    const publicKeyBuffer = base642Buffer(publicKeyBase64);
    const publicKey = await crypto.subtle.importKey(
        "spki",
        publicKeyBuffer,
        {
            name: "RSA-OAEP",
            hash: { name: "SHA-256" }
        },
        true,
        ["encrypt"]
    );

    const encoder = new TextEncoder();
    const encodedData = encoder.encode(data);

    const encryptedBuffer = await crypto.subtle.encrypt(
        {
            name: "RSA-OAEP"
        },
        publicKey,
        encodedData
    );

    return buffer2base64(encryptedBuffer);
}


async function decryptData(privateKeyBase64, encryptedString) {
    const privateKeyBuffer = base642Buffer(privateKeyBase64);
    const privateKey = await crypto.subtle.importKey(
        "pkcs8",
        privateKeyBuffer,
        {
            name: "RSA-OAEP",
            hash: { name: "SHA-256" }
        },
        true,
        ["decrypt"]
    );

    const encryptedBuffer = base642Buffer(encryptedString);

    const decryptedBuffer = await crypto.subtle.decrypt(
        {
            name: "RSA-OAEP",
        },
        privateKey,
        encryptedBuffer
    );

    const decoder = new TextDecoder();
    return decoder.decode(decryptedBuffer);
}

module.exports = { generateWebCryptoKeyPair, encryptData, decryptData }