//This is the client portion for ipfs uploads
const axios = require('axios');
const FormData = require('form-data');
const utils = require("./utils");

const SERVER_LOCATION = utils.SERVER_LOCATION;

function generateNonce() {
    return Date.now().toString();
}

export const uploadJSONToIPFS = async(JSONBody) => {
    const url = `${SERVER_LOCATION}/ipfsUpload/json`;

    JSONBody.nonce = generateNonce();

    return axios 
        .post(url, JSONBody)
        .then(function (response) {
            console.log("Server Response:", response.data);
            return {
                success: true,
                pinataURL: response.data.ipfsUrl
            };
        })
        .catch(function (error) {
            console.log(error)
            return {
                success: false,
                message: error.message,
            }

    });
};

export const uploadFileToIPFS = async(file) => {
    const url = `${SERVER_LOCATION}/ipfsUpload/file`;

    const nonce = generateNonce();
    
    let data = new FormData();
    data.append('file', file);

    const metadata = JSON.stringify({
        name: file.name,
        keyvalues: {
            nonce: nonce
        }
    });

    console.log("Upload metadata:", metadata);

    data.append('ipfsMetadata', metadata);

    return axios 
        .post(url, data, {
            maxBodyLength: 'Infinity',
            headers: {
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
            }
        })
        .then(function (response) {
            console.log("image uploaded", response.data.ipfsUrl)
            return {
               success: true,
               pinataURL: response.data.ipfsUrl
           };
        })
        .catch(function (error) {
            console.log(error)
            return {
                success: false,
                message: error.message,
            }

    });
};